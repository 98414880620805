

    import Flickity from 'flickity'
    import { defineComponent, isRef, onMounted, onUnmounted, PropType, ref } from 'vue'
    import Picture from './Picture.vue'
    import CustomCursor from './CustomCursor.vue'
    import { useTranslation } from '../compositions/UseTranslation'
    import LoopingVideo from '../components/LoopingVideo.vue'
    // import BlockRevealAnimation from '../components/BlockRevealAnimation.vue'
    import ClippingAnimation from '../components/ClippingAnimation.vue'
    import { isVideoType } from '../utils'
    import { AssetInterface } from '../Interfaces/AssetInterface'

    export default defineComponent({
        name: 'Slider',
        components: {
            CustomCursor,
            Picture,
            LoopingVideo,
            ClippingAnimation
            // BlockRevealAnimation
        },
        props: {
            images: { type: Array as PropType<AssetInterface[]>, default: () => [] },
            sizes: { type: Array as PropType<number[][]>, default: () => [] },
            zoomOnHover: { type: Boolean, default: true },
            defaultGradient: { type: String, default: null }
        },
        setup(props) {

            /**
             * Flickity Slider
             */
            const sliderReference = ref<Element | null>()

            let flickity: Flickity | null = null

            onMounted(() => {

                if (sliderReference.value) {

                    flickity = new Flickity(sliderReference.value, {
                        cellSelector: '.slider__cell',
                        accessibility: true,
                        setGallerySize: true,
                        resize: true,
                        contain: true,
                        imagesLoaded: true,
                        draggable: true,
                        freeScroll: true,
                        wrapAround: false,
                        initialIndex: 0,
                        watchCSS: false,
                        cellAlign: 'left',
                        pageDots: false,
                        autoPlay: false,
                        prevNextButtons: false
                    })

                    /**
                     * Triggering resize in initial stage is necessary to prevent carousel cell from collapsing on mobile end
                     */
                    flickity.resize()

                }

            })

            onUnmounted(() => {

                if (flickity) {

                    flickity.destroy()

                }

            })

            /**
             * Custom cursor
             */
            const { cursor } = useTranslation()

            return {
                sliderReference,
                lang: cursor,
                isVideoType,
                sizeForIndex(index: number) {

                    const size = props.sizes[index]

                    if (isRef(size)) {

                        return {
                            width: size.value[0],
                            height: size.value[1]
                        }

                    }

                    return size
                        ? {
                            width: size[0],
                            height: size[1]
                        }
                        : null

                }
            }

        }
    })


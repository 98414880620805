import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoopingVideo = _resolveComponent("LoopingVideo")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_ClippingAnimation = _resolveComponent("ClippingAnimation")!
  const _component_CustomCursor = _resolveComponent("CustomCursor")!

  return (_openBlock(), _createBlock(_component_CustomCursor, {
    text: _ctx.lang.drag,
    "is-dark": ""
  }, {
    default: _withCtx(({ eventListeners }) => [
      _createElementVNode("div", _mergeProps({
        ref: "sliderReference",
        class: "slider"
      }, eventListeners), [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (media, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "slider__cell px-12 sm:px-4"
          }, [
            _createVNode(_component_ClippingAnimation, { interactive: _ctx.zoomOnHover }, {
              default: _withCtx(() => [
                (_ctx.isVideoType(media.desktop_asset.mime_type))
                  ? (_openBlock(), _createBlock(_component_LoopingVideo, {
                      key: 0,
                      source: media.desktop_asset.permalink,
                      "default-gradient": _ctx.defaultGradient,
                      style: _normalizeStyle(_ctx.sizeForIndex(index))
                    }, null, 8, ["source", "default-gradient", "style"]))
                  : (_openBlock(), _createBlock(_component_Picture, _mergeProps({
                      key: 1,
                      image: media
                    }, _ctx.sizeForIndex(index), { "default-gradient": _ctx.defaultGradient }), null, 16, ["image", "default-gradient"]))
              ]),
              _: 2
            }, 1032, ["interactive"])
          ]))
        }), 128))
      ], 16)
    ]),
    _: 1
  }, 8, ["text"]))
}
import { AssetInterface } from '../Interfaces/AssetInterface'
import { isVideoType } from '../utils'
import { useResponsiveValue } from './UseResponsiveValue'

export const useCarouselSizes = (assets: AssetInterface[], whatWeDo = false) => {

    return assets.map((asset: any) => {

        const media = asset.desktop_asset

        if (isVideoType(media.mime_type)) {

            const height = whatWeDo ? media.height : media.height * 0.4
            const width = whatWeDo ? media.width : media.width * 0.4

            return useResponsiveValue([
                [ `${ width * 0.5 }px`, `${ height * 0.5 }px` ],
                [ `${ width }px`, `${ height }px` ]
            ])

        }

        const width = whatWeDo ? media.width / 2 : media.width * 0.6
        const height = whatWeDo ? media.height / 2 : media.height * 0.6

        return [ [ width * 0.5, width ], [ height * 0.5, height ] ]

    })

}
